// ======================= LOAD GLOBAL ELEMENTS =========================== //

@import '../styles/modules/all';

// =========================== CUSTOM STYLES ============================== //


/*
  Posible variable can be found in following SCSS Files:

  ../styles/modules/_colors.scss
  ../styles/modules/_fonts.scss
  ../styles/modules/_variables.scss
 */

$header-background-color: #101820;
$highlight-color: #9D2235;


.logo {
  background-image: url("/assets/images/theme/porsche.png");
  margin: 1rem auto !important;
  height: 150px !important;
}

.product-logo {
  background-image: url("/assets/images/theme/porsche-nav-logo.png");
}

.card {
  .logo {
    width: 300px !important;
  }
}

.center-panel {
  .heading {
    position: relative !important;
    top: -5px !important;
  }

  .center-content {
    margin-top: -5px;
  }
}

.articleTabSet .nav.nav-tabs .nav-link.active, .left-panel .nav.nav-tabs .nav-link.active {
  border-bottom: none !important;
}

select::-ms-expand {
  display: none;
}

.top-bar {
  .fa-caret-down {
    top: 0 !important;
  }
}

.header {
  .product-logo {
    margin-left: -5px !important;
    margin-right: -5px !important;
    width: 145px !important;
  }

  .top-header {
    .right-header {
      .cooperation-image {
        display: none;
      }
    }

    .left-header {
      .burger-nav {
        .cooperation-image {
          display: none;
        }
      }
    }
  }
}

.card {
  .cooperation {
    display: none;
  }
}

.price {
  color: $highlight-color !important;
}


.external-tools-wrapper.car-details .row {
  color: $highlight-color !important;
}

.deselect {
  background-color: $highlight-color !important;
}

.vehicle-type-button {
  &.active {
    color: $highlight-color !important;
  }

  &:hover {
    .fa {
      color: $highlight-color !important;
    }
  }
}

// ================================ LAST ELEMENTS ========================== //

/*               MUST BE LOADED IN EVERY THEME AFTER OVERWRITES              */

// ======================================================================== //


@import 'includes';
